<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">Vulnerable Customer Policy</h1>
      </div>
      <div class="box b-shdw-3 content">
        <p>Covered2Go Travel Insurance (trading as Rush Insurance Services Limited) understand that some people or
          groups are vulnerable or experience periods of vulnerability in their lives. We endeavour to ensure those
          customers, and all customers, consistently experience friendly, good, fair treatment, service and outcomes.
          This statement outlines a little more on why and how we do that.
        </p>
        <p>
          In 2021, The FCA (Financial Conduct Authority) <a
            href="https://www.fca.org.uk/publications/finalised-guidance/guidance-firms-fair-treatment-vulnerable-customers"
            target="_blank">published guidance</a> to help UK organisations service their customers and better
          understand and serve the needs of vulnerable customers, who are defined as follows:
          A vulnerable customer is someone who, due to their personal circumstances, is especially susceptible to
          harm, particularly when a firm is not acting with appropriate levels of care.
        </p>
        <p> Below is a list, with examples (but not limited to) of people and groups who may be vulnerable. However,
          we understand that vulnerability is or can be personal and that every individual is unique. We know grouping
          people as such never tells the whole story.
        </p>
        <ul>
          <li>Life stage or age</li>
          <li>Bereavement</li>
          <li>Financial difficulties / cost of living problems</li>
          <li>Mental health</li>
          <li>Language, dialogue and communication issues</li>
          <li>Health and medical conditions</li>
          <li>Financial crime victims</li>
          <li>Physical abuse victims</li>
          <li>Levels of low or disposable income</li>
          <li>Alcohol, drug, gambling and other addictions</li>
          <li>Circumstantial life changing events</li>
        </ul>
        <p>Rush Insurance Service agents are trained to identify signs of vulnerability, which include (but aren’t
          limited to) difficulty understanding English language or dialogue, difficulty with numeracy, lack of
          understanding with specific terminology, any two-way communication issues, any unusual or erratic behaviour
          and any signs of emotion or distress.</p>
        <p>To ensure our business learns and evolves, our teams receive ongoing training and support, and all
          interactions are logged for review. We want to ensure our customers feel cared for and so we don’t stop
          trying to be better in how we identify, engage, serve and deliver for vulnerable customers.</p>
        <p>If we believe a customer may be vulnerable then relevant questions are asked to better understand the
          individual’s circumstances and we adapt our approach accordingly. We collect this information securely and
          without explicit consent and for the sole purpose of protecting customer outcomes and further improving how
          we can adapt to support our customers during a period of vulnerability. Should we recognise that a customer
          requires specialist help or advice we will refer them to an appropriate individual or external
          organisation.</p>
        <p>If a customer has specific communication needs, we aim to engage an appropriate individual or organisation
          to help to mediate or interpret in-line with data protection / GDPR legislation.</p>
        <p>More information and guidelines can be found on the <a
            href="https://www.fca.org.uk/publications/finalised-guidance/guidance-firms-fair-treatment-vulnerable-customers"
            target="_blank">FCA website</a>.</p>
        <p>(Last updated September 2024)</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "VulnerableCustomerStatement",
}
</script>

<style lang="scss" scoped>
.level-item {
  padding: 40px 0 40px 0;
}

.content ::v-deep {
  h4 {
    color: $c2g_orange;
    margin-bottom: 8px !important;
  }

}
</style>